<template>
    <div>
        <form
            @submit.prevent="validateForm(formScope)"
            :data-vv-scope="formScope"
            :ref="formScope"
        >
            <Basics
                :formScope="formScope"
                :create="create"
                :template="template"
                :templateData="templateData"
                :recordLanguage="recordLanguage"
                :forcedLanguage="forcedLanguage"
                :contactItemCategories="contactItemCategories"
                @on-changed-record-language="$emit('on-changed-record-language', $event)"
                @help-mode="$emit('help-mode', $event)"
                ref="basics"
            />
        </form>
    </div>
</template>

<script>
import Basics from './Basics';

export default {
    name: 'Step1',
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        templateData: {
            type: Array,
            default: () => []
        },
        formScope: {
            type: String,
            default: null
        },
        recordLanguage: {
            type: String,
            default: null
        },
        forcedLanguage: {
            type: String,
            default: null
        },
        contactItemCategories: {
            type: Array,
            default: () => []
        },
    },
    components: {
        Basics,
    }
};
</script>
